.blog-list {
	display: flex;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;

	&.result {
		border-bottom: none;
	}
}

.blog-card {
	margin-bottom: 2rem;
	position: relative;
	width: 100%;

	.card-body {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.card-img-top {
		width: 100%;
		overflow: hidden;
		height: 12rem;

		img {
			min-height: 12rem;
			min-width: 100%;
			object-fit: cover;
		}

		.default-cover {
			height: 100%;
			width: 100%;
			padding: 1rem;
			display: flex;
			align-items: center;
			justify-content: center;
			background: $gray-200;

			font-size: 1.2rem;
			font-weight: 500;
			color: $gray-600;
		}
	}

	.blog-card-footer {
		display: flex;
		align-items: center;
		margin-top: 0.5rem;

		.avatar {
			margin-right: 0.5rem;
			border-radius: 50%;
		}
	}
}

.blog-container {
	font-size: 1rem;
	max-width: 800px;
	margin: 0px auto;

	.blog-title {
		margin-top: 1rem;

		@include media-breakpoint-up(xl) {
			line-height: 1;
			font-size: $font-size-4xl;
		}
	}

	.blog-footer {
		display: flex;
		justify-content: space-between;
		color: $text-muted;
		margin-top: 3rem;
	}

	.blog-intro {
		font-size: 1.125rem;
		font-weight: 400;
	}

	.blog-content {
		margin-bottom: 1rem;

		.blog-header {
			margin-bottom: 3rem;
			margin-top: 3rem;
		}
	}
}
