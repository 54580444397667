.web-sidebar {
	padding-top: 2rem;
	position: sticky;
	top: 0;
}

.sidebar-item a {
	display: block;
	padding: 0.25rem 0.5rem;
	margin-top: 0.25rem;
	border-radius: 0.375rem;
	font-size: $font-size-sm;
	color: $gray-600;
	text-decoration: none;
	font-weight: 500;
	@include transition();

	&:hover {
		color: $gray-900;
	}
}

// Remove top margin from frist child
.sidebar-item:first-child a {
	margin-top: 0rem;
}

.sidebar-item a.active {
	color: $primary;
	background-color: $primary-light;
}

.sidebar-item-icon {
	width: 24px;
	height: 24px;
	display: inline-block;
}

.sidebar-group {
	margin-bottom: 1rem;

	h6 {
		font-size: $font-size-sm;
		margin-bottom: 0.75rem;
		line-height: 1.5;
	}

	> ul {
		padding-left: 0.5rem;
		margin-bottom: 2rem;
	}
}
