$gray-50: #F9FAFA !default;
$gray-100: #F4F5F6 !default;
$gray-200: #EEF0F2 !default;
$gray-300: #E2E6E9 !default;
$gray-400: #C8CFD5 !default;
$gray-500: #A6B1B9 !default;
$gray-600: #74808B !default;
$gray-700: #4C5A67 !default;
$gray-800: #313B44 !default;
$gray-900: #192734 !default;

$black: #000 !default;
$primary: #2490ef !default;
$primary-light: lighten($primary, 42%) !default;
$light: $gray-50 !default;

$body-color: $gray-700 !default;
$text-muted: $gray-600 !default;
$border-color: $gray-300 !default;
$headings-color: $gray-900 !default;

$font-size-xs: 0.75rem !default;
$font-size-sm: 0.875rem !default;
$font-size-base: 1rem !default;
$font-size-lg: 1.125rem !default;
$font-size-xl: 1.25rem !default;
$font-size-2xl: 1.5rem !default;
$font-size-3xl: 1.875rem !default;
$font-size-4xl: 2.25rem !default;
$font-size-5xl: 3rem !default;
$font-size-6xl: 4rem !default;

$btn-padding-y-lg: 1rem !default;
$btn-padding-x-lg: 2.5rem !default;
$btn-font-size-lg: 1.125rem !default;
$btn-line-height-lg: 1 !default;
$btn-border-radius-lg: 0.5rem !default;
$btn-border-radius: 0.375rem !default;
$btn-font-size: $font-size-sm !default;
$btn-padding-x: 1rem !default;
$btn-padding-y: 0.5rem !default;
$btn-font-weight: 500 !default;

$navbar-nav-link-padding-x: 1rem !default;
$navbar-padding-y: 1rem !default;
$card-border-radius: 0.75rem !default;
$card-spacer-y: 0.5rem !default;

$dropdown-font-size: $font-size-sm !default;
$dropdown-border-radius: 0.375rem !default;
$dropdown-item-padding-y: 0.5rem !default;
$dropdown-item-padding-x: 0.5rem !default;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  2xl: 1440px
) !default;

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import "~bootstrap/scss/mixins";

$code-color: $purple;
