.from-markdown {
	color: $gray-700;
	line-height: 1.625;

	> * + * {
		margin-top: 1rem;
	}

	> :first-child {
		margin-top: 0;
	}

	ul,
	ol {
		padding-left: 2.5rem;
	}

	ul {
		list-style-type: disc;
	}

	ol {
		list-style: decimal;
	}

	li > * + * {
		margin-top: 1rem;
	}

	> ul > * + *,
	> ol > * + * {
		margin-top: 1rem;
	}

	> blockquote {
		padding: 1.25rem 1rem;
		font-size: $font-size-sm;
		font-weight: 500;
		border: 1px solid $gray-200;
		border-left: 3px solid $yellow;
		border-top-left-radius: 0.1rem;
		border-bottom-left-radius: 0.1rem;
		border-top-right-radius: 0.375rem;
		border-bottom-right-radius: 0.375rem;
		margin: 1.5rem 0;
	}

	blockquote p:last-child {
		margin-bottom: 0;
	}

	b, strong {
		color: $gray-800;
	}

	h1, h2, h3, h4, h5, h6 {
		color: $gray-900;
	}

	h1 + p {
		margin-top: 0.75rem;
		font-size: $font-size-base;

		@include media-breakpoint-up(sm) {
			margin-top: 1.25rem;
			font-size: 1.125rem;
		}
		@include media-breakpoint-up(md) {
			font-size: 1.25rem;
		}
	}

	h2 {
		margin-bottom: 1rem;
		margin-top: 3.5rem;
	}

	h3 {
		margin-top: 3rem;
		margin-bottom: 1rem;
		font-weight: 600;
		line-height: 1.25;
		font-size: $font-size-xl;
	}

	h4 {
		margin-top: 2.5rem;
		margin-bottom: 1rem;
		font-size: 1.125rem;
		font-weight: 600;
		line-height: 1.25;
	}

	h5 {
		margin-top: 2rem;
		margin-bottom: 1rem;
		font-size: $font-size-base;
		font-weight: 600;
		line-height: 1.25;
	}

	h6 {
		margin-top: 1.5rem;
		margin-bottom: 1rem;
		font-size: $font-size-sm;
		font-weight: 600;
		line-height: 1.25;
	}

	tr > td,
	tr > th {
		font-size: $font-size-sm;
		padding: 0.5rem;
	}

	th:empty {
		display: none;
	}

	.screenshot {
		border: 1px solid $gray-400;
		border-radius: 0.375rem;
	}

	.screenshot + em {
		text-align: center;
		display: block;
		margin-top: 0.5rem;
		margin-bottom: 2rem;
	}

	code:not(.hljs) {
		padding: 0 0.25rem;
		background: $light;
		border-radius: 0.125rem;
	}
}
